import {
  createRouter,
  createWebHashHistory,
  // createWebHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../views/storeHomeNew.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/loginPage.vue"),
  },
  {
    path: "/mine",
    component: () => import("../views/minePage.vue"),
  },
  {
    path: "/question",
    component: () => import("../views/questionPage.vue"),
  },
  {
    path: "/feedback",
    component: () => import("../views/feedbackPage.vue"),
  },
  {
    path: "/newcomer",
    component: () => import("../views/newcomerPage.vue"),
  },
  {
    path: "/help",
    component: () => import("../views/helpPage.vue"),
  },
  {
    path: "/service",
    component: () => import("../views/servicePage.vue"),
  },
  {
    path: "/wallet",
    component: () => import("../views/walletPage.vue"),
  },
  {
    path: "/supplier",
    component: () => import("../views/supplierPage.vue"),
  },
  {
    path: "/goods",
    component: () => import("../views/goodsList.vue"),
  },
  {
    path: "/goods_detail",
    component: () => import("../views/goodsDetail.vue"),
  },
  {
    path: "/showcase",
    component: () => import("../views/showcasePage.vue"),
  },
  {
    path: "/order",
    component: () => import("../views/orderPage.vue"),
  },
  {
    path: "/order_detail",
    component: () => import("../views/orderDetail.vue"),
  },
  {
    path: "/completeInfo",
    component: () => import("../views/completeInfo.vue"),
  },
  {
    path: "/withdraw",
    component: () => import("../views/withdrawPage.vue"),
  },
  {
    path: "/ranking",
    component: () => import("../views/rankingPage.vue"),
  },
  {
    path: "/withdraw_order",
    component: () => import("../views/withdrawOrder.vue"),
  },
  {
    path: "/rebate_record",
    component: () => import("../views/rebateRecord.vue"),
  },
  {
    path: "/recharge",
    component: () => import("../views/rechargePage.vue"),
  },
  {
    path: "/set_pwd",
    component: () => import("../views/setPwd.vue"),
  },
  {
    path: "/verified",
    component: () => import("../views/verifiedPage.vue"),
  },
  {
    path: "/message_list",
    component: () => import("../views/messageList.vue"),
  },
  {
    path: "/message_chat",
    component: () => import("../views/messageChat.vue"),
  },
  {
    path: "/store_detail",
    component: () => import("../views/storeDetail.vue"),
  },
  {
    path: "/store_list",
    component: () => import("../views/storeList.vue"),
  },
  {
    path: "/store_good_list",
    component: () => import("../views/storeGoodList.vue"),
  },
  {
    path: "/notice_list",
    component: () => import("../views/noticeList.vue"),
  },
  {
    path: "/my_collect",
    component: () => import("../views/myCollect.vue"),
  },
  {
    path: "/merchants_settled",
    component: () => import("../views/merchantsSettled.vue"),
  },
  {
    path: "/update_pwd",
    component: () => import("../views/updatePwd.vue"),
  },
  {
    path: "/mjzc",
    name: "mjzc",
    component: () => import("../views/mjzc.vue"),
  },
  {
    path: "/thxy",
    name: "thxy",
    component: () => import("../views/thxy.vue"),
  },
  {
    path: "/shqh",
    name: "shqh",
    component: () => import("../views/shqh.vue"),
  },
  {
    path: "/yszc",
    name: "yszc",
    component: () => import("../views/yszc.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(), // no-#
  history: createWebHashHistory(), // #
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 滚动位置进入新page时回到顶部
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
